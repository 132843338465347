<template>
  <v-layout wrap>
    <v-card v-if="!showMatrix &&!showReport && !showOverview">
      <v-card-text>
      <v-dialog
        ref="dialog1"
        v-model="modal1"
        :return-value.sync="startDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getStartDate"
          label="Van"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="startDate"
          show-week
          full-width
          @input="$refs.dialog1.save(startDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-dialog
        ref="dialog2"
        v-model="modal2"
        :return-value.sync="endDate"
        width="290px"
        lazy>
        <v-text-field
          slot="activator"
          v-model="getEndDate"
          label="Tot en met"
          readonly
        ></v-text-field>
        <v-date-picker 
          locale="nl-nl"
          v-model="endDate"
          :min="startDate"
          show-week
          full-width
          @input="$refs.dialog2.save(endDate)" 
          scrollable>
        </v-date-picker>
      </v-dialog>
      <v-autocomplete
        v-if="employees.length > 0 && startDate && endDate"
        :items="items"
        :item-text="getEmployeeName"
        return-object
        :search-input.sync="search"
        v-model="selectedEmployee"
        hide-no-data
        hide-details
        label="Selecteer een medewerker"
      ></v-autocomplete>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
          v-if="startDate && endDate && employees.length && !selectedEmployee"
          class="primary"
          @click="getOverview">
          Totaal overzicht
        </v-btn>
        <v-btn 
          v-if="startDate && endDate && employees.length && selectedEmployee"
          class="primary"
          @click="displayReport">
          Urenlijst
        </v-btn>
        <v-btn 
          v-if="startDate && endDate && employees.length && selectedEmployee"
          class="primary"
          @click="displayMatrix">
          Urenkaart
        </v-btn>                
      </v-card-actions>
    </v-card>
    <hourBookingReport 
      v-if="showReport" 
      :hourBookings="hourBookings"
      :startDate="startDate"
      :endDate="endDate"
      :subject="selectedEmployee.firstname"
      :roles="roles"
      v-on:done="resetReport">
    </hourBookingReport>
    <hourBookingMatrix 
      v-if="showMatrix" 
      :hourBookings="hourBookings"
      :startDate="startDate"
      :endDate="endDate"
      :subject="selectedEmployee.firstname"
      v-on:done="resetReport">
    </hourBookingMatrix>    
    <hourBookingOverview
      v-if="showOverview" 
      :hourBookings="hourBookings"
      :startDate="startDate"
      :endDate="endDate"
      v-on:done="resetReport"
      v-on:openReport="openReport({$event})">
    </hourBookingOverview>
    <v-dialog v-model="dialog" max-width="300px">
      <v-alert
        :value="true"
        type="error"
      >
        {{dialogText}}
      </v-alert>      
    </v-dialog>
  </v-layout>
</template>

<script>
import hourBookingMatrix from '../../components/HourBookingMatrix.vue'
import hourBookingReport from '../../components/HourBookingReport.vue'
import hourBookingOverview from '../../components/HourBookingOverview.vue'
export default {
  name: 'Employee',
  props: ['roles'],
  components: {
    hourBookingMatrix,
    hourBookingReport,
    hourBookingOverview
  },
  data () {
    return {
      search: null,
      items: [],
      employees: [],
      showMatrix: false,
      showReport: false,
      showOverview: false,
      selectedEmployee: null,
      startDate: null,
      endDate: null,
      hourBookings: [],
      dialog: false,
      dialog1: false,
      dialog2: false,
      modal1: false,
      modal2: false,
      dialogText: ''
    }
  },
  watch: {
    search (val) {
      if (val == '') this.items = this.employees
      val && val !== this.select
    },
    endDate () {
      if (this.startDate && this.endDate) this.getEmployees()
    },
    startDate (val) {
      var date = new Date(val)
      date.setMonth(date.getMonth()+1)
      date.setDate(0)
      this.endDate = date.toISOString().substr(0, 10)
      if (this.startDate && this.endDate) this.getEmployees()
    }
  },
  computed: {
    getStartDate () {
      return this.$moment(this.startDate).format('dddd D MMMM')
    },
    getEndDate () {
      return this.$moment(this.endDate).format('dddd D MMMM')
    } 
  },
  methods: {
    getEmployees () {
      this.selectedEmployee = null
      this.$http.get('api/employees/getbyactivty/' + this.startDate + '-' + this.endDate)
        .then(request => {
          if (!request.data && request.data.length < 1) {
            this.dialogText = 'Geen resultaten gevonden!'
            this.dialog = true
          } else {
            this.employees = request.data
            this.items = this.employees
          }           
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    getEmployeeName: function(employee) {
      return ((employee.firstname || '') + ' ' + (employee.surnamePrefix || '') + ' ' +  (employee.surname || '')).replace('  ', ' ')
    },
    resetReport: function() {
      this.selectedEmployee = null
      this.selectedMonth = null
      this.showMatrix = false
      this.showReport = false
      this.hourBookings = []
      this.showOverview = false
    },
    openReport(event) {
      this.hourBookings = []
      this.showOverview = false
      this.showMatrix = false
      this.showReport = true
      this.selectedEmployee = this.employees[this.employees.findIndex(e => e.id == event.$event)]
      this.displayReport()
    },
    getOverview () {      
      this.$http.get('api/report/overview/E' + this.startDate + '-' + this.endDate)
        .then(request => {
          this.hourBookings = request.data
          if (!this.hourBookings || this.hourBookings.length < 1) {
            this.dialogText = 'Geen resultaten gevonden!'
            this.dialog = true
          } else {
            this.showOverview = true
          }          
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    },
    displayReport() {
      this.getReport()
      this.showReport = true
    },
    displayMatrix() {
      this.getReport()
      this.showMatrix = true
    },
    getReport () {      
      this.$http.get('api/report/E' + this.startDate + '-' + this.endDate + '-' + this.selectedEmployee.id)
        .then(request => {
          this.hourBookings = request.data
        })
        .catch(() => { console.error('api error!') }) /* eslint-disable-line no-console */
    }
  },
  mounted: function () {
    var date = new Date()
    date.setDate(1)
    date.setMonth(date.getMonth()-1)
    this.startDate = date.toISOString().substr(0, 10)
  }
}
</script>
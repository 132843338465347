var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":"","xl12":""}},[_c('h3',[_vm._v("Urenkaart van "+_vm._s(_vm.subject)+" periode "+_vm._s(_vm.startDate)+" tot en met "+_vm._s(_vm.endDate))]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-actions":"","sort-icon":""},scopedSlots:_vm._u([{key:"items",fn:function(props){return _vm._l((_vm.headers),function(header){return _c('td',{key:header.text,class:[
          {'success' : props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && _vm.getMatrixValue(props.item, header) >= 8},
          {'extra' : props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && _vm.getMatrixValue(props.item, header) > 8},
          {'extra' : props.index == 0 && !header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && _vm.getMatrixValue(props.item, header) > 0},
          {'error': props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && _vm.getMatrixValue(props.item, header) < 8},
          {'grey': props.index == 0 && !header.isWeekday && header.text != 'Totaal' && header.text != 'Project'},
          {'text-xs-center': header.text != 'Project'},
          {'text-no-wrap': header.text == 'Project'}]},[_vm._v(" "+_vm._s(_vm.getMatrixValue(props.item, header))+" ")])})}}])}),_c('v-btn',{staticClass:"error ml-0",on:{"click":function($event){return _vm.$emit('done')}}},[_vm._v(" Sluiten ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
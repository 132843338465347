<template>
<v-flex xs12 sm12 md12 lg12 xl12>
  <h3>Urenkaart van {{subject}} periode {{startDate}} tot en met {{endDate}}</h3>
  <v-data-table
  :headers="headers"
  :items="items"
  hide-actions
  sort-icon=""
  class="elevation-1">  
    <template slot="items" slot-scope="props">
      <td 
        v-for="header in headers" 
        :key="header.text" 
        :class="[
          {'success' : props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && getMatrixValue(props.item, header) >= 8},
          {'extra' : props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && getMatrixValue(props.item, header) > 8},
          {'extra' : props.index == 0 && !header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && getMatrixValue(props.item, header) > 0},
          {'error': props.index == 0 && header.isWeekday && header.text != 'Totaal' && header.text != 'Project' && getMatrixValue(props.item, header) < 8},
          {'grey': props.index == 0 && !header.isWeekday && header.text != 'Totaal' && header.text != 'Project'},
          {'text-xs-center': header.text != 'Project'},
          {'text-no-wrap': header.text == 'Project'}]">
          {{ getMatrixValue(props.item, header) }}
        </td>
    </template>
  </v-data-table>
  <v-btn 
  class="error ml-0" 
  @click="$emit('done')">
      Sluiten
  </v-btn>
</v-flex>
</template>
<style>
  table.v-table tbody td:first-child, table.v-table tbody td:not(:first-child), table.v-table tbody th:first-child, table.v-table tbody th:not(:first-child), table.v-table thead td:first-child, table.v-table thead td:not(:first-child), table.v-table thead th:first-child, table.v-table thead th:not(:first-child) {
    padding: 0 4px
  }
</style>

<script>
export default {
  name: 'hourBookingMatrix',
  props: ['hourBookings', 'startDate', 'endDate', 'subject'],
  data () {
    return {
      headers: [],
      items: []
    }
  },
  watch: {
    hourBookings() {
      this.headers = []
      this.items = []
      if (this.hourBookings && this.hourBookings.length > 0) {
        var startMoment = this.$moment(this.startDate)
        var endMoment = this.$moment(this.endDate)
        var numDays = endMoment.diff(startMoment, 'days') + 1
        var thisDay = startMoment
        this.headers = [{text: 'Project', value: 'string'}, {text: 'Totaal', value: 'string'}]
        var daylist = []
        while(daylist.length < numDays) {
          var isWeekday = true
          if (thisDay.isoWeekday() > 5) isWeekday = false
          var newDay = {
            'text': thisDay.format('DD-MM'),
            'date': thisDay.format('YYYY-MM-DD'),
            'value': 'date',
            'isWeekday': isWeekday,
            'sortable': false
          }
          daylist.push(newDay)
          thisDay = startMoment.add(1, 'days')
        }
        this.headers = this.headers.concat(daylist)
        var projects = this.hourBookings.map(b => b.clientName + ' ' + b.projectName).filter((v, i, a) => a.indexOf(v) === i)
        projects.forEach(project => {
          var days = []
          this.headers.forEach(header => {
            if (header.text != 'Project') {
              days.push({
                  date: header.date, 
                  amount: this.hourBookings
                    .filter(b => b.date.substr(0, 10) === header.date && b.clientName + ' ' + b.projectName == project)
                    .map(b => b.amount)
                    .reduce((partial_sum, a) => partial_sum + a, 0)})
            }
          })
          this.items.push({project: project, total: days.map(d => d.amount).reduce((partial_sum, a) => partial_sum + a), days: days})
        })
        var daytotals = []
        this.headers.forEach(header => {
            if (header.text != 'Project') {
              daytotals.push({
                date: header.date,
                amount: this.hourBookings.filter(b => b.date.substr(0, 10) === header.date).map(b => b.amount).reduce((partial_sum, a) => partial_sum + a, 0)
              })
          }
        })
        this.items.unshift({project: 'Totaal', total: this.items.map(i => i.total).reduce((partial_sum, a) => partial_sum + a, 0), days: daytotals})
      }
    }
  },
  methods: {
    getMatrixValue(item, header) {
      if (header.text == 'Project') {
        return item.project
      } else if(header.text == 'Totaal') {
        return item.total
      } else {
        var amount = item.days[item.days.map(i => i.date).indexOf(header.date)].amount
        if (amount > 0) return amount
        return ''
      }
    },
    formatDate(date) {
      return this.$moment(date).format('ddd DD-MM-YYYY')
    }    
  }
}
</script>